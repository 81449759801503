<template>
  <div class="feedback">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'Feedback'
}
</script>

<style lang="sass">
.feedback
  position: relative
  display: flex
  height: 100%
  .column-1
    width: 420px
    margin-right: 130px
  .column-2
    width: 355px
  &__title
    font-weight: bold
    font-size: 24px
    line-height: 24px
    margin-bottom: 30px
  &__templates
    margin: 0
    padding: 0
    list-style: none
    li
      position: relative
      display: flex
      align-items: center
      height: 55px
      width: 100%
      padding-right: 25px
      padding-left: 15px
      background: #FFFFFF
      font-size: 12px
      line-height: 12px
      border-radius: 12px
      box-shadow: 0px 0px 17px -6px lightgrey
      cursor: pointer
      transition: 0.1s linear
      &:not(:last-child)
        margin-bottom: 10px
      &:before
        content: url('~@/assets/images/svg/arrow-right.svg')
        position: absolute
        right: 15px
      &:hover
        background: #1AC386
        color: #ffffff
        &:before
          content: url('~@/assets/images/svg/arrow-right-white.svg')
        a
          color: inherit
      a
        display: flex
        align-items: center
        width: 100%
        color: #3B3F47
        height: 100%
  &__item
    position: relative
    background: #FFFFFF
    border: 0.5px solid #CBCBCB
    border-radius: 12px
    transition: 0.1s linear
    &:not(:last-child)
      margin-bottom: 15px
    &:last-child
      margin-bottom: 35px
    &:before
      content: url('~@/assets/images/svg/arrow-right.svg')
      position: absolute
      right: 15px
      top: 46px
    &:hover
      cursor: pointer
      background: #F8F8F8
    a
      display: block
      padding: 15px 25px 19px 17px
      color: inherit
      width: 100%
      height: 100%
      text-decoration: none
    .head
      display: flex
      justify-content: space-between
      font-size: 12px
      line-height: 12px
      color: #cbcbcb
      margin-bottom: 5px
      .status
        font-weight: 600
        &.received
          color: #1AC386
        &.rejected
          color: #E93030
        &.resolved
          color: rgba(64, 134, 244, 0.8)
    .title
      font-weight: 600
      font-size: 14px
      line-height: 14px
      margin-bottom: 5px
    .description
      -ms-text-overflow: ellipsis
      text-overflow: ellipsis
      overflow: hidden
      -webkit-line-clamp: 2
      line-clamp: 2
      display: -webkit-box
      word-wrap: break-word
      -webkit-box-orient: vertical
      font-size: 14px
      line-height: 18px
  &__inprogress,
  &__completed
    width: 100%
    margin: 0
    padding: 0
    list-style: none
    &__title
      font-weight: bold
      font-size: 20px
      line-height: 18px
      margin-bottom: 15px

@media screen and (max-width: 1440px)
  .feedback
    .column-1
      width: 350px
      margin-right: 80px
    .column-2
      width: 350px

// 996
@media screen and (max-width: 1180px)
  .feedback
    .column-1
      width: 330px
      margin-right: 60px
    .column-2
      width: 330px

// 900
@media screen and (max-width: 1024px)
  .feedback
    .column-1
      width: 300px
      margin-right: 40px
    .column-2
      width: 300px

// 768
@media screen and (max-width: 912px)
  .feedback
    .column-1
      width: 260px
      margin-right: 20px
    .column-2
      width: 260px

// 540
@media screen and (max-width: 800px)
  .feedback
    flex-wrap: wrap
    .column-1
      width: 100%
      margin-right: 0
    .column-2
      width: 100%
    &__templates
      li:last-child
        margin-bottom: 30px
</style>
